<template>
    <div class="teaching-research-classroom-observation-records-view">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content-wrapper">
               <expand-filter
                    :formData="formData"
                    :closeWidth="1360"
                    @clickBtn="clickBtn"
                    @changeBtnFormType="changeBtnFormType"
                    @changeSel="changeSel"
                    @changeCascader="changeCascader"
                >
                <div class="inline" slot="other-btn" v-has-permi="['patrolRecord:add']"></div>
                <div slot="other-btn" style="flex: 1;display: flex;">
                    <Option
                        @addObservationRecords="addObservationRecords"
                    />
                </div>
                </expand-filter>
               <div class="table-wrapper">
                    <table-data
                        ref="table"
                        id="table"
                        v-loading="loadingTable"
                        :config="table_config"
                        :tableData="table_data"
                        :backgroundHeader="backgroundHeader"
                        :headerColor="headerColor"
                        @handleSelectionChange="handleSelectionChange"
                    >
                        <template v-slot:operation="slotData">
                            <el-button type="text" @click="edit(slotData.data)" class="table-success-button" v-has-permi="['patrolRecord:detail']">查看</el-button>
                            <el-button type="text" @click="del(slotData.data)" :disabled="slotData.data.patrolTeacherId != userId" class="table-danger-button" v-has-permi="['patrolRecord:delete']">删除</el-button>
                        </template>
                    </table-data>
               </div>
               <Pagination v-if="show" :total="total" :page.sync="listQuery.pageNum" :limit.sync="listQuery.pageRow" @pagination="getList" />
            </div>
        </div>
        <error v-else :errorShow="errorShow"></error>
    </div>
</template>
<script>
    import {
        // 业务组件
        Pagination,
        // 功能组件
        Loading,
        Error,
    } from 'common-local';
    import TableData from "@/components/scrollWrapper/Sub/TableData"
    import {
        classScheduleModel
    } from '@/models/ClassSchedule.js';
    import { downloadFile } from '@/libs/utils.js'
    import { TeachingResearchCourseScheduleTempModel } from '@/models/TeachingResearchCourseScheduleTemp.js';
    import FilterWrapper from './FilterWrapper.vue';
    import Option from './Option.vue';
    import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter";
    import onResize from "@/mixins/onResize";
    import RecordsList from '@/libs/pageListOptimal.js';
    import {formatTreeData} from "@/libs/utils";
    import { mapState } from 'vuex';
    export default {
        name: "TeachingResearchClassroomPatrolRecordsView",
        mixins: [onResize],
        components: {
            Loading,
            Error,
            Pagination,
            TableData,
            FilterWrapper,
            Option,
            ExpandFilter
        },
        props: {

        },
        data () {
            return {
                loadingShow: false,
                errorShow: false,
                formData: {
                    data: [
                        {
                            type: 'input',
                            label: '',
                            placeholder: '方案名称',
                            key: 'programmeName',
                            value: '',
                        },
                        {
                            type: "cascader",
                            label: "",
                            value: [],
                            placeholder: "班级",
                            key: "classIds",
                            organType: "",
                            cascaderProps: {
                                label: "name",
                                value: "id",
                                emitPath: false,
                                multiple: true,
                            },
                            list: [],
                            filter(data) {
                                return data.organType !== '1';
                            },
                        },
                        {
                            type: 'input',
                            label: '',
                            placeholder: '巡课教师',  //层级显示
                            key: 'patrolTeacherName',
                            value: '',
                        },
                        {
                            type: 'input',
                            label: '',
                            placeholder: '上课教师',
                            key: 'classTeacherName',
                            value: ''
                        },
                        {
                            type: 'select',
                            label: '',
                            placeholder: '科目',
                            key: 'subjectName',
                            value: '',
                            width: '',
                            list: [],
                        }
                    ],
                    btnList: [
                        {
                            type: 'primary',
                            text: '查询',
                            fn: 'primary',
                        },
                        {
                            type: 'enquiry',
                            text: '重置',
                            fn: 'reset'
                        },
                    ],
                    btnFormType: true
                },
                /**
                 * 筛选数据
                 * */
                queryList: {
                    programmeName:'',//方案名称
                    classCond:[],//班级
                    patrolTeacherName:'',//巡课教师
                    classTeacherName:'',//上课教师
                    subjectId:'',
                },
                total: 0,
                listQuery:{
                    pageNum:1,
                    pageRow:20,
                    schoolId:"",
                    productId:"cloud_campus",
                    programmeName:'',//方案名称
                    classCond:[],//班级
                    patrolTeacherName:'',//巡课教师
                    classTeacherName:'',//上课教师
                    subjectId:'',
                },
                /**
                 * 筛选下拉选项
                 * subjectList  科目列表
                 * */
                subjectList:[],
                /**
                 * 表格数据
                 * */
                loadingTable: false,
                table_config: {
                    thead: [{
                        label: "巡课方案",
                        prop: "programmeName",
                        align: "center",
                        labelWidth:'120'
                    },
                    {
                        label: "巡课方式",
                        prop: "programmeType",
                        type:'function',
                        align: "center",
                        labelWidth:'120',
                        callBack(row) {
                        if (row.programmeType == 1) {
                            return '自由巡课'
                        } else if (row.programmeType == 2) {
                            return '固定课程巡课'
                        }}
                    },
                    {
                        label: "上课教师",
                        prop: "classTeacherName",
                        labelWidth:'120',
                        align: "center",
                    },
                    {
                        label: "年级",
                        prop: "gradeName",
                        align: "center",
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "班级",
                        prop: "className",
                        align: "center",
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "科目",
                        prop: "subjectName",
                        align: "center",
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "上课时间",
                        prop: "classTime",
                        labelWidth:'240',
                        align: "center",
                    },
                    {
                        label: "分值",
                        prop: "score",
                        labelWidth:'160',
                        align: "center",
                    },
                    {
                        label: "巡课教师",
                        prop: "patrolTeacherName",
                        align: "center",
                    },
                    {
                        label: "提交时间",
                        prop: "createTime",
                        labelWidth:'160',
                        align: "center",
                        type:'function',
                        callBack(row) {
                            return row.createTime.slice(0, row.createTime.length - 3)
                        }
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: 'operation',
                        className: 'text-spacing-reduction',
                        minWidth:'170px'
                    }
                    ],
                    check: false,
                    rowkey: 'id',
                    height: '',
                    isExpandAll: true
                },
                table_data: [],
                backgroundHeader: '#FAFBFC',
                headerColor: '#595959',
                schoolId:'',
                show:false,
                exportLoading: false
            }
        },
        created(){
            let _this = this;
            this.$on('goToDetail', (par) => {
                _this.goToDetail(par)
            })
            this.listQuery.schoolId = this.schoolId = this.$store.state.schoolId;
            this.init()
            this.getOrgList()
        },
        computed:{
            ...mapState({
                userId: state => state.userId
            })
        },
        methods: {
            /**
             * @Description:
             * @DoWhat: 获取班级下拉列表
             * @UseScenarios:
             * @Attention:
             * @Author: xwn
             * @Date: 2023-06-12 14:36:32
             */
            async getOrgList() {
                await this._fet("/school/schoolOrgan/getAllGradeClassList", {
                    schoolId: this.$store.state.schoolId,
                }).then((res) => {
                    if (res.data.code === "200") {
                        console.log('班级下拉列表的值',res.data.data)
                        this.formData.data.forEach((item) => {
                            if (item.key === "classIds") {
                                item.list = formatTreeData(
                                    res.data.data,
                                    "id",
                                    "parentOrg",
                                );
                            }
                        });
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            async goToDetail (params) {
                console.log(params,'params')
                await this.initPageData()
                await this.detail (params)
            },
            async init(){
                this.loadingTable = true;
                await this.initPageData();
                this.setPageInfo();
                this.show = true;
                await this.getList()
            },
            clickBtn(ev) {
                switch (ev.item.fn) {
                    case 'primary':
                        this.formData.data.forEach(e => {
                            if (e.key !== 'supervisingTeacher') {
                                this.queryList[e.key] = e.value
                            }
                        })
                        console.log('巡课记录点击查询了')
                        this.inquiry()
                        break;
                    case 'reset':
                        this.formData.data.forEach((item) => {
                            item.value = '';
                        })
                        this.reset()
                        break;
                    default:
                        break;
                }
            },
            /**
             * @Description: 日期
             * @DoWhat:
             * @UseScenarios:
             * @Attention:
             * @Author: 陈金伟
             * @Date: 2023-07-19 19:00:37
             */
            datePickerBlur() {
                this.choiceDate = ''
            },
            savePageInfo(){
                //存储筛选项及滚动条高度
                let recordsList =  new RecordsList()
                recordsList.set(this.$route.name, {
                    formData: this.formData,
                    listQuery: this.listQuery,
                    tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
                })
            },
            setPageInfo(){
                //赋值--存储筛选项及滚动条高度
                let recordsList =  new RecordsList()
                if(recordsList.get(this.$route.name)){
                    this.formData = recordsList.get(this.$route.name).formData;
                    this.listQuery = recordsList.get(this.$route.name).listQuery;
                    this.listQuery.pageNum = Number(this.listQuery.pageNum)
                }
            },
            setTableScrollHeight(){
                let recordsList =  new RecordsList()
                if(recordsList.get(this.$route.name)){
                    setTimeout(()=>{
                        this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                        //移除--存储筛选项及滚动条高度
                        recordsList.remove(this.$route.name)
                    },500)
                }
            },
            /**
             * @Description: 巡课记录列表
             * @DoWhat:
             * @UseScenarios:
             * @Attention:
             * @Author: xwn
             * @Date: 2022-11-17 16:51:26
             */
            getList(){
                this.loadingTable = true;
                const classscheduleModel = new classScheduleModel();
                classscheduleModel.getPatrolRecordList(this.listQuery).then((res) =>{
                console.log('巡课列表：',res.data)
                if (res.data.code == '200') {
                    let list = res.data.data.list;
                    // list.forEach((item) => {
                    //     if (item.createTime) {
                    //         this.$set(item, 'newCreateTime',item.createTime.substring(0, item.createTime.length - 3) )
                    //     } else {
                    //         this.$set(item, 'newCreateTime', '')
                    //     }
                    // })
                    this.table_data = list;
                    this.total = res.data.data.totalCount;
                    this.setTableScrollHeight();
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
                this.loadingTable = false;
            })
            },
            /**
             * @Description: 头部筛选数据初始化
             * @DoWhat: 头部筛选
             * @UseScenarios:
             * @Attention:
             * @Author: xwn
             * @Date: 2022-11-18 09:51:19
             */
            async initPageData(){
                const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
                // 获取科目下拉列表
                await teachingResearchCourseScheduleTempModel.getSchoolSubjectsList({'schoolId': this.schoolId, 'dictKey':"teachingSubjects"}).then((res) => {
                    if (res.data.code == '200') {
                        this.subjectList = res.data.data
                        this.formData.data.find(e => e.key === 'subjectName').list = res.data.data.map(item=>{
                            return Object.assign({}, item,{
                                label: item.name,
                                value: item.id
                            } )
                        })
                    } else if (res.data.msg){
                        this.$message.error(res.data.msg)
                    }
                })
            },
            /**
         * @Description: 写入禁用项
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2022-11-28 10:09:29
         */
         setDisabledOption (list) {
            list.forEach((item) => {
                if (item.key !== 'teacher') {
                    this.$set(item, 'disabled', true)
                } else {
                    this.$set(item, 'disabled', false)
                }

                if (item.children && item.children.length > 0) {
                    this.setDisabledOption (item.children)
                }
            })
         },
         changeCascader(data){
            if(data.data.cascaderProps.multiple){
                let nodes =data.ref.getCheckedNodes()
                // console.log('nodes',nodes)
                let nodeidstr=''
                if(nodes.length>0){
                    for (var i = 0; i < nodes.length; i++) {
                        let nodeitem = nodes[i]
                        if (nodeitem.children.length==0) {
                            nodeidstr = nodeidstr + ',' + nodes[i].value
                        }
                    }
                    nodeidstr = nodeidstr.slice(1);
                    this.listQuery.classCond = nodeidstr.split(',')
                    this.queryList.classCond = nodeidstr.split(',')
                }else{
                    this.listQuery.classCond = []
                    this.queryList.classCond = []
                }
            }else if (data.ref.getCheckedNodes()[0]) {
                let node;
                node = data.ref.getCheckedNodes()[0].data;
                this.queryList.classCond=node.id;
            } else {
                this.queryList.classCond = ''
            }
         },

            /**
             * @Description: 分页查询巡课记录
             * @Author: mrz
             * @Date: 2024-08-24 08:56:14
             */
            inquiry () {
                this.listQuery.pageNum = 1
                this.listQuery.pageRow = 20
                this.listQuery.programmeName = this.queryList.programmeName
                this.listQuery.patrolTeacherName = this.queryList.patrolTeacherName
                this.listQuery.classTeacherName = this.queryList.classTeacherName
                this.getList(0)

            },
            /**
             * @Description: 重置
             * @DoWhat: 重置
             * @UseScenarios: 重置
             * @Attention: 无
             * @Author: 武东超
             * @Date: 2022-11-11 10:24:14
             */
             reset () {
                this.listQuery.pageNum = 1
                this.listQuery.pageRow = 20
                this.listQuery.programmeName =this.queryList.programmeName = ''
                this.listQuery.classCond =this.queryList.classCond= []
                this.listQuery.patrolTeacherName =this.queryList.patrolTeacherName= ''
                this.listQuery.classTeacherName =this.queryList.classTeacherName= ''
                this.listQuery.subjectId =this.queryList.subjectId= ''
                this.getList(0)
             },
            /**
             * @Description: 添加巡课记录
             * @Author: mrz
             * @Date: 2024-08-23 14:16:04
             */
            addObservationRecords () {
                console.log('添加巡课记录')
                const obj = {
                    type:'add'
                }
                this.$emit('handlerGoClassPatrolClick_record',obj)
            },
            // table相关
            /**
             * @Description: 选中tableIdx
             * @DoWhat: 选中tableIdx
             * @UseScenarios: 选中tableIdx
             * @Attention: 无
             * @Author: 武东超
             * @Date: 2022-11-11 10:29:15
             * @param {*} e
             */
            handleSelectionChange (e) {
                console.log(e,'2')
            },
            deduplication (arr) {
                let map = new Map();
                for (let item of arr) {
                    if (!map.has(item.id)) {
                        map.set(item.id, item);
                    }
                }
                return [...map.values()];
            },

            /**
             * @Description: 查看巡课页面
             * @Author: mrz
             * @Date: 2022-11-11 09:59:26
             */
            edit (data) {
                let obj = {
                    id: data.id,
                    type: 'details',
                };
                this.$emit('handlerGoClassPatrolClick_record', obj)
            },
            /**
             * @Description: 继续巡课
             * @DoWhat: 继续巡课
             * @UseScenarios: 继续巡课
             * @Attention: 无
             * @Author: 武东超
             * @Date: 2022-11-20 16:36:26
             */
             continueTour (data) {
                const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
                teachingResearchCourseScheduleTempModel.getContinueTourInfo({id: data.id, watchTitle: data.watchTitle}).then((res) => {
                    if (res.data.code == '200') {
                        let selectT = [];

                        this.getTeach1(this.supervisingTeacherList, res.data.data ,selectT)
                        selectT = this.deduplication(selectT);
                        let obj = {
                            id: data.id,
                            type: 'continueTour',
                            list: res.data.data,
                            selectT: selectT,
                            form: 'closeAdd'
                        };
                        this.savePageInfo();
                        this.$emit('handlerGoClassPatrolClick_record', obj)
                    } else if (res.data.msg){
                        this.$message.error(res.data.msg)
                    }
                })
             },
            /**
             * @Description: 巡课页面
             * @DoWhat: 巡课页面
             * @UseScenarios: 巡课页面
             * @Attention: 无
             * @Author: 武东超
             * @Date: 2022-11-11 10:00:03
             */
            detail (data) {
                const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
                teachingResearchCourseScheduleTempModel.getSchoolClassRecordInfo({id: data.id, watchTitle: data.watchTitle}).then((res) => {
                    if (res.data.code == '200') {
                        let selectT = [];
                        this.getTeach(this.supervisingTeacherList, res.data.data.supervisingTeacherId ,selectT)
                        selectT = this.deduplication(selectT);
                        let obj = {
                            id: data.id,
                            type: 'detail',
                            list: [res.data.data],
                            selectT: selectT
                        };
                        this.savePageInfo();
                        this.$emit('handlerGoClassPatrolClick_record', obj)
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg)
                    }
                })
            },
            getTeach(supervisingTeacherList, supervisingTeacherId, selectT) {
                supervisingTeacherList.forEach((subItem) => {
                    if (supervisingTeacherId == subItem.id) {
                        let obj = {
                            id: subItem.id,
                            teacherName: subItem.name
                        };

                        selectT.push(obj)
                    }

                    if (subItem.children && subItem.children.length > 0) {
                        this.getTeach(subItem.children, supervisingTeacherId, selectT)
                    }
                })
            },
            getTeach1 (supervisingTeacherList, data, selectT) {
                supervisingTeacherList.forEach((subItem) => {
                    data.forEach((item) => {
                    if (item.supervisingTeacherId == subItem.id) {
                        let obj = {
                            id: subItem.id,
                            teacherName: subItem.name
                        };

                        selectT.push(obj)
                    }
                })

                if (subItem.children && subItem.children.length > 0) {
                    this.getTeach1(subItem.children, data, selectT)
                }
                })
            },
            /**
             * @Description: 删除列表记录
             * @DoWhat: 删除列表记录
             * @UseScenarios: 删除列表记录
             * @Attention: 无
             * @Author: 武东超
             * @Date: 2022-11-11 10:00:07
             */
            del (data) {
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const param = {
                        id: data.id
                    }
                    this._fet('/school/schoolPatrolRecord/delete', param).then((res) => {
                        if (res.data.code == '200') {
                            this.$message.success('删除成功!')
                            this.getList()
                        } else if (res.data.msg){
                            this.$message.error(res.data.msg)
                        }
                    })
                }).catch(() => {
                })
            },
            // 修改科目查询项
            changeSel(data) {
                console.log('科目修改：',data,this.subjectList)
                this.listQuery.subjectId = data.value
            },
            //根据年级获取班级下拉列表
            async getClassList(gradeId){
                const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
                await teachingResearchCourseScheduleTempModel.getSchoolClassList({'schoolId': this.schoolId, 'organType': '4','parentList':gradeId }).then((res) => {
                if (res.data.code == '200') {
                    this.classList = res.data.data.list;
                    this.formData.data.find(e => e.key === 'class').list = res.data.data.list.map(item=>{
                        return Object.assign({}, item,{
                            label: item.name,
                            value: item.id
                        } )
                    })
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
                })
            }
        },
        mounted(){
            console.log('sessionStorage.getItem("patrol-schedule")',sessionStorage.getItem("patrol-schedule"))
            if(sessionStorage.getItem("patrol-schedule") == 'true'){
                this.$emit('handlerGoClassPatrolClick_record',{type:'add'})
                sessionStorage.setItem("patrol-schedule","false")
            }else{
                setTimeout(() => {
                    this.table_config.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 132
                })
            }
        }
    }
</script>
<style lang="scss" scoped>

.inline {
        margin: 4px 10px;
        width: 1px;
        height: 24px;
        background-color: #E6E6E6;
    }
    .teaching-research-classroom-observation-records-view {
        .content-wrapper {
            padding-right: 10px;
            .table-wrapper {
                margin-top: 10px;
                box-sizing: border-box;
            }
        }
    }
    .table-del-none{
        color: #888;
    }
::v-deep .el-cascader__tags .el-tag .el-icon-close{
    display: none;
}
</style>
