<template>
    <div class="option-wrapper">
        <el-button type="primary" v-has-permi="['patrolRecord:add']" @click="addObservationRecords" >
            <span>添加</span>
        </el-button>
        <div
            style="
            flex: 1;
            display: inline-flex;
            margin-left:10px;
        ">
        </div>
    </div>
</template>
<script>
    export default {
        name: "Option",
        methods: {
            addObservationRecords () {
                this.$emit('addObservationRecords')
            }
        }
    }
</script>
<style lang="scss" scoped>
    .option-wrapper {
        box-sizing: border-box;
        // padding-bottom: 10px;
        display: flex;
        flex: 1;
    }
</style>
